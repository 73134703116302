// Utilities
import {
    Store,
} from 'vuex';
export const set = (property: string) => (state: any, payload: any) => (state[property] = payload);

export const toggle = (property: string) => (state: any) => (state[property] = !state[property]);


export const builCommit = (s: Store<any>) => (m?: string) => (c: string) => (v: any) => s.commit(`${(m) ? m + '/' : ''}${c}`, v);


export const builActions = (s: Store<any>) => (m?: string) => (dispatch: string) => (value?: any) => s.dispatch(`${(m) ? m + '/' : ''}${dispatch}`, value);
