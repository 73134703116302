import { set, toggle } from '@/shared/utils/vuex';

const toggleContactForm = (state: any) => {
    state.contacto = !state.contacto;
};

export default {
    setCrumb: set('crumb'),
    toggleContactForm,
};
